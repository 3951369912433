import { Capacitor, HttpResponse } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  IonButton,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";

export default function LocationPreferences() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [state, setState] = useState<LocationStateType>({
    detected: false,
    reason: "idle",
  });
  const [inProgress, setInProgress] = useState(false);
  const hasRequestedToUpdate = useRef(false);
  useEffect(() => {
    if (state.detected && !hasRequestedToUpdate.current) {
      updateUsersLocation(state.latitude, state.longitude, savedUser.token)
        .then((result) => {
          if (result.status !== 200) {
            return Promise.reject(result);
          }
          const user = {
            ...savedUser.user,
            location: result.data.location,
          };
          return userContext?.loginHook.saveUser({
            ...savedUser,
            user: user,
          });
        })
        .then(() => history.goBack())
        .catch((e) => handleError(e, userContext))
        .finally(() => {
          hasRequestedToUpdate.current = false;
        });
    }
    return () => {
      if (state.detected) {
        hasRequestedToUpdate.current = true;
      }
    };
  }, [state]);
  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
          </header>
          <main className="flex h-full w-full flex-col justify-between px-3 pb-3">
            <IonContent className="h-full w-full" scrollY={true}>
              <div className="mt-4 flex w-full flex-col gap-1 px-3">
                <div className="flex w-full flex-col gap-2">
                  <IonText className="text-2xl font-bold">Location</IonText>
                  <IonText>
                    {!state.detected &&
                      state.reason === "idle" &&
                      "You should grant location permission in order to match you with the people in your city."}
                    {!state.detected &&
                      state.reason === "rejected" &&
                      "You have rejected BetterDates to access your location. In order to continue, please give location access from:"}
                    {!state.detected &&
                      state.reason === "missing_location_service" &&
                      "Location services are not enabled on your device. Please enable location services from your device's Settings first."}
                    {!state.detected &&
                      state.reason === "error" &&
                      "There was a problem when processing your request. Please try again."}
                    {state.detected && "Processing..."}
                  </IonText>
                  {!state.detected && state.reason === "rejected" && (
                    <IonText className="text-sm font-bold text-gray-600">
                      {Capacitor.getPlatform() === "android" &&
                        "Settings > Apps > BetterDates > Permissions > Location"}
                      {Capacitor.getPlatform() === "ios" &&
                        "Settings > Apps > BetterDates > Location"}
                      {Capacitor.getPlatform() === "web" &&
                        "Your browser or if you're on mobile, you might want to look under Settings and see if Location Services are enabled."}
                    </IonText>
                  )}
                </div>
              </div>
            </IonContent>
            <div className="flex w-full flex-row justify-end">
              <IonButton
                className={"mt-8 h-9 w-20"}
                shape={"round"}
                color={"dark"}
                size={"small"}
                hidden={state.detected}
                disabled={
                  (!state.detected && state.reason === "rejected") || inProgress
                }
                onClick={(e) => {
                  e.preventDefault();
                  setInProgress(true);
                  Geolocation.checkPermissions()
                    .then((result) => result.location)
                    .then((permission) => {
                      switch (permission) {
                        case "denied":
                          setState({
                            detected: false,
                            reason: "rejected",
                          });
                          return Promise.reject(permission);
                        default:
                          return Geolocation.getCurrentPosition();
                      }
                    })
                    .then((position) => {
                      const coordinate = position.coords;
                      setState({
                        detected: true,
                        latitude: coordinate.latitude.toString(),
                        longitude: coordinate.longitude.toString(),
                      });
                    })
                    .catch((e) => {
                      if (
                        e.code === GeolocationPositionError.PERMISSION_DENIED ||
                        e === "denied"
                      ) {
                        setState({ detected: false, reason: "rejected" });
                        userContext?.errorHook.logError("I", e);
                      } else if (
                        (e.message || "").includes(
                          "Location services are not enabled",
                          0,
                        )
                      ) {
                        setState({
                          detected: false,
                          reason: "missing_location_service",
                        });
                        userContext?.errorHook.logError("I", e);
                      } else {
                        setState({
                          detected: false,
                          reason: "error",
                        });
                        handleError(e, userContext);
                      }
                    })
                    .finally(() => {
                      setInProgress(false);
                    });
                }}
              >
                {!state.detected &&
                  (state.reason === "idle" || state.reason === "rejected") &&
                  "Proceed"}
                {!state.detected &&
                  (state.reason === "error" ||
                    state.reason === "missing_location_service") &&
                  "Retry"}
              </IonButton>
            </div>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

interface LocationState {
  detected: boolean;
}

interface LocationDetectedState extends LocationState {
  detected: true;
  latitude: string;
  longitude: string;
}

interface LocationNotDetectedState extends LocationState {
  detected: false;
  reason: NotDetectedReason;
}

type LocationStateType = LocationDetectedState | LocationNotDetectedState;

type NotDetectedReason =
  | "idle"
  | "rejected"
  | "error"
  | "missing_location_service";

function updateUsersLocation(
  latitude: string,
  longitude: string,
  token: string,
): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/location`,
    headers: {
      Authorization: token,
    },
    body: {
      latitude: latitude,
      longitude: longitude,
    },
  });
}
