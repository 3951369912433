import { HttpResponse } from "@capacitor/core";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import {
  IonButton,
  IonChip,
  IonContent,
  IonLabel,
  IonPage,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import AboutSelfExampleModal from "../../../components/modal/AboutSelfExampleModal";
import Modal, { ModalStateType } from "../../../components/modal/Modal";
import { JIBI_BASE_URL, PATCH } from "../../../util/ApiClient";
import { UserContext } from "../../../util/BetterDatesApp";
import { handleError } from "../../../util/error";
import User from "../../../data/User";

export default function AboutSelfInformation() {
  const history = useHistory();
  const userContext = useContext(UserContext);
  const loggedIn = userContext?.userState.loggedIn;
  if (!loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const [about, setAbout] = useState(
    userContext.userState.savedUser.user.about || "",
  );
  const [modalState, setModalState] = useState<ModalStateType>({
    isVisible: false,
  });

  const showAboutSelfExampleModal = () => {
    setModalState({
      isVisible: true,
      content: <AboutSelfExampleModal setModalState={setModalState} />,
      title: "",
    });
  };
  const handleModalDismiss = () => {
    setModalState({
      isVisible: false,
    });
  };

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col justify-between">
          <header className="flex h-14 flex-row items-center justify-between px-3">
            <IonChip
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <ChevronLeftIcon className="me-1 h-5 w-5 text-gray-500" />
              <IonLabel>Back</IonLabel>
            </IonChip>
          </header>
          <main className="flex h-full w-full flex-col justify-between px-3 pb-3">
            <IonContent className="h-full w-full" scrollY={true}>
              <div className="mt-4 flex w-full flex-col gap-1 px-3">
                <div className="flex w-full flex-col gap-2">
                  <IonText className="text-2xl font-bold">Who are you?</IonText>
                  <IonText>
                    Describe yourself in a couple of sentences. We use this
                    information to learn more about you, to be able to find your
                    perfect match.
                  </IonText>
                  <div className="mt-8 bg-gray-100">
                    <IonTextarea
                      className="w-full border-2 border-dashed align-text-top"
                      placeholder="I am..."
                      value={about}
                      counter={true}
                      spellcheck={true}
                      maxlength={2048}
                      autoGrow={true}
                      color={"dark"}
                      onIonInput={(e) => {
                        const newInput = e.detail.value as string;
                        setAbout(newInput);
                      }}
                    />
                  </div>
                </div>
              </div>
            </IonContent>
            <div className="flex w-full flex-row justify-end">
              <IonButton
                className={"mt-8 h-9 w-20"}
                shape={"round"}
                color={"dark"}
                disabled={about.length === 0}
                size={"small"}
                onClick={(e) => {
                  e.preventDefault();
                  const trimmedAboutSelf = about.replace(/\s{2,}/g, " ").trim();
                  if (trimmedAboutSelf.length < 96) {
                    showAboutSelfExampleModal();
                    return;
                  }
                  patchUserAbout(trimmedAboutSelf, savedUser.token)
                    .then((result) => {
                      if (result.status !== 200) {
                        return Promise.reject(result);
                      }
                      const newUser = {
                        ...savedUser.user,
                        about: result.data.about,
                      } as User;
                      return userContext?.loginHook?.saveUser({
                        ...savedUser,
                        user: newUser,
                      });
                    })
                    .then(() => history.goBack())
                    .catch((e) => handleError(e, userContext));
                }}
              >
                Save
              </IonButton>
            </div>
            <Modal
              isOpen={modalState.isVisible}
              onDismiss={handleModalDismiss}
              title={modalState.isVisible ? modalState.title : ""}
              dismiss={modalState.isVisible ? modalState.dismiss : undefined}
            >
              {modalState.isVisible ? modalState.content : ""}
            </Modal>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function patchUserAbout(about: string, token: string): Promise<HttpResponse> {
  return PATCH({
    url: `${JIBI_BASE_URL}/v1/user/about`,
    headers: {
      Authorization: token,
    },
    body: {
      about: about,
    },
  });
}
