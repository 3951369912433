import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import {
  ClockIcon,
  ExclamationCircleIcon,
  HeartIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import { RangeValue } from "@ionic/core";
import { IonButton, IonPage, IonText, useIonViewDidEnter } from "@ionic/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Header from "../../components/Header";
import MatchInfo, { ProfilePictureStateType } from "../../components/MatchInfo";
import MatchSlider from "../../components/MatchSlider";
import { MatchesResponse } from "../../data/MatchesResponse";
import { ApplicationStatus } from "../../data/User";
import { getMatches } from "../../datasource/match-datasource";
import {
  getUserApplicationStatus,
  patchUserAvatar,
} from "../../datasource/user-datasource";
import { ERROR_DEFAULT } from "../../i18n/static";
import { compressAvatar, getAvatarFile } from "../../util/AvatarExt";
import { UserContext } from "../../util/BetterDatesApp";
import { handleError } from "../../util/error";
import { requestNotificationPermission } from "../../util/NotificationExt";
import {
  isAboutValid,
  isAvatarValid,
  isGenderValid,
  isLocationValid,
  isMatchPreferencesPresent,
  isSexualOrientationValid,
} from "../../validation/ProfileValidation";
import "./Home.css";

export default function Home() {
  const userContext = useContext(UserContext);
  const queryClient = useQueryClient();
  const [homeState, setHomeState] = useState<HomeState>({ type: "loading" });
  if (!userContext?.userState?.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;

  const validationChecks = useMemo(
    () => ({
      isMatchPreferencesValid: isMatchPreferencesPresent(savedUser.user).valid,
      hasSexualOrientation: isSexualOrientationValid(savedUser.user).valid,
      hasGender: isGenderValid(savedUser.user).valid,
      hasLocation: isLocationValid(savedUser.user).valid,
      hasAvatar: isAvatarValid(savedUser.user).valid,
      hasAboutSelf: isAboutValid(savedUser.user).valid,
    }),
    [savedUser.user],
  );

  const applicationStatusQuery = useQuery({
    queryKey: [userContext.queryKeys.applicationStatus],
    queryFn: () =>
      getUserApplicationStatus(savedUser).then((response) => {
        if (response.status !== 200) {
          return Promise.reject(response);
        }
        return response.data as ApplicationStatusResponse;
      }),
    enabled: Object.values(validationChecks).every(Boolean),
  });

  const matchesQuery = useQuery({
    queryKey: [userContext.queryKeys.matches],
    queryFn: () => getMatches(savedUser),
    enabled: () => {
      return (
        applicationStatusQuery.isSuccess &&
        applicationStatusQuery.data.items[0].status ===
          ApplicationStatus.Approved
      );
    },
  });

  useEffect(() => {
    if (applicationStatusQuery.isLoading || matchesQuery.isLoading) {
      setHomeState({ type: "loading" });
    }
  }, [applicationStatusQuery.isLoading, matchesQuery.isLoading]);

  useEffect(() => {
    if (applicationStatusQuery.isError) {
      setHomeState({
        type: "error",
        error: ERROR_DEFAULT,
        queryKey: userContext.queryKeys.applicationStatus,
      });
    }
  }, [applicationStatusQuery.isError]);

  useEffect(() => {
    if (matchesQuery.isError) {
      setHomeState({
        type: "error",
        error: ERROR_DEFAULT,
        queryKey: userContext.queryKeys.matches,
      });
    }
  }, [matchesQuery.isError]);

  useEffect(() => {
    const applicationStatus = applicationStatusQuery.data;
    if (
      applicationStatus &&
      applicationStatus.items &&
      applicationStatus.items.length > 0
    ) {
      const status = applicationStatus.items[0].status as ApplicationStatus;
      const rejectionReasons =
        applicationStatus.items[0].expand?.rejectionReasons;
      if (status !== ApplicationStatus.Approved) {
        setHomeState({
          type: "applicationStatus",
          status: status,
          rejectionReasons: rejectionReasons,
        });
      }
    }
  }, [applicationStatusQuery.data]);

  useEffect(() => {
    if (
      !validationChecks.isMatchPreferencesValid ||
      !validationChecks.hasGender ||
      !validationChecks.hasSexualOrientation ||
      !validationChecks.hasAboutSelf
    ) {
      setHomeState({ type: "missingPreferences" });
      return;
    }
    if (!validationChecks.hasAvatar) {
      setHomeState({ type: "missingAvatar" });
      return;
    }
    if (!validationChecks.hasLocation) {
      setHomeState({ type: "missingLocation" });
      return;
    }
  }, [validationChecks]);

  useEffect(() => {
    const matches = matchesQuery.data?.matches;
    if (!matches) {
      return;
    }
    if (matches.length > 0) {
      setHomeState({ type: "loaded" });
    } else {
      setHomeState({ type: "noMatches" });
    }
  }, [matchesQuery.data]);

  useIonViewDidEnter(() => {
    if (
      matchesQuery.data ||
      applicationStatusQuery.data?.items?.[0]?.status ===
        ApplicationStatus.Approved
    ) {
      requestNotificationPermission(userContext);
    }
  });

  return (
    <IonPage className="flex items-center justify-center bg-gray-50">
      <div className="h-full w-full max-w-md">
        <div className="safe-scroller flex h-full w-full flex-col">
          <Header />
          <main className="flex h-full w-full items-center justify-center">
            <div className="flex h-full w-full flex-col px-8">
              {homeState.type === "loading" && <EmptyStateLoading />}
              {homeState.type === "error" && (
                <EmptyStateError
                  errorMessage={homeState.error}
                  onRetry={() => {
                    queryClient.invalidateQueries({
                      queryKey: [homeState.queryKey],
                    });
                  }}
                />
              )}
              {homeState.type === "applicationStatus" && (
                <EmptyStateApplicationStatus
                  status={homeState.status}
                  rejectionReasons={homeState.rejectionReasons}
                />
              )}
              {homeState.type === "missingPreferences" && (
                <EmptyStateMissingPreferences />
              )}
              {homeState.type === "missingAvatar" && (
                <EmptyStateMissingAvatar />
              )}
              {homeState.type === "missingLocation" && (
                <EmptyStateMissingLocation />
              )}
              {homeState.type === "noMatches" && <EmptyStateNoMatches />}
              {homeState.type === "loaded" && <LoadedState />}
            </div>
          </main>
        </div>
      </div>
    </IonPage>
  );
}

function EmptyStateLoading() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <p>Loading...</p>
    </div>
  );
}

function EmptyStateError(props: { errorMessage: string; onRetry: () => void }) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex w-full flex-col items-center gap-4">
        <IonText>{props.errorMessage}</IonText>
        <IonButton
          color="dark"
          onClick={(e) => {
            e.preventDefault();
            props.onRetry();
          }}
        >
          Retry
        </IonButton>
      </div>
    </div>
  );
}

function EmptyStateApplicationStatus(props: {
  status: ApplicationStatus;
  rejectionReasons?: RejectionReason[];
}) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex w-full flex-col items-center gap-4">
        {props.status === ApplicationStatus.InReview && (
          <>
            <ClockIcon className="h-8 w-8 animate-pulse text-yellow-500" />
            <IonText className="text-center">
              Your profile is currently being reviewed.<br></br> We appreciate
              your patience. Please check back later for updates on your status.
            </IonText>
          </>
        )}
        {props.status === ApplicationStatus.Rejected && (
          <>
            <ExclamationCircleIcon className="h-8 w-8 text-red-500" />
            <IonText className="text-start text-xl font-semibold">
              Your profile was rejected.
            </IonText>
            {props.rejectionReasons && props.rejectionReasons.length > 0 && (
              <div>
                <IonText className="text-start">Because:</IonText>
                <ul className="mt-2 list-inside list-disc">
                  {props.rejectionReasons.map((reason) => (
                    <li key={reason.reason} className="text-sm">
                      Your {reason.description_EN}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function EmptyStateMissingPreferences() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex w-full flex-col gap-4">
        <IonText className="text-center">
          Please set your match preferences so we can match you with the right
          person!
        </IonText>
        <IonButton color="dark" routerLink="/preferences">
          Set Preferences
        </IonButton>
      </div>
    </div>
  );
}

function EmptyStateMissingAvatar() {
  const userContext = useContext(UserContext);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const savedUser = userContext.userState.savedUser;
  const queryClient = useQueryClient();

  const avatarMutation = useMutation({
    mutationFn: async (avatarFile: File | Blob) => {
      const result = await patchUserAvatar(savedUser, avatarFile);
      if (result.status !== 200) {
        throw new Error("Failed to update avatar");
      }
      const body = await result.json();
      return body;
    },
    onSuccess: (data) => {
      const updatedUser = {
        ...savedUser.user,
        avatar: data.avatar,
      };
      userContext?.loginHook.saveUser({
        ...savedUser,
        user: updatedUser,
      });
      queryClient.invalidateQueries({
        queryKey: [userContext.queryKeys.applicationStatus],
      });
    },
    onError: (error) => handleError(error, userContext),
  });

  const handleAvatarSelection = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      const result = await Camera.getPhoto({
        source: CameraSource.Photos,
        allowEditing: false,
        saveToGallery: false,
        quality: 90,
        resultType: CameraResultType.DataUrl,
      });

      if (!result.dataUrl) {
        throw new Error("No image data received");
      }

      const avatarFile = await getAvatarFile(savedUser.user.id, result.dataUrl);
      if (!avatarFile) {
        throw new Error("Failed to process avatar file");
      }

      const compressedAvatar = await compressAvatar(avatarFile);
      avatarMutation.mutate(compressedAvatar);
    } catch (e: any) {
      handleError(e, userContext);
    }
  };
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8">
      <div className="flex w-full flex-col items-center">
        <div
          className="flex h-36 w-28 flex-col items-center justify-center rounded-md border-2 border-dotted border-gray-500"
          onClick={handleAvatarSelection}
        >
          <UserPlusIcon className="h-8 w-8 text-gray-500" />
        </div>
      </div>
      <div className="flex w-full flex-col gap-4">
        <IonText className="text-start">
          Choose an avatar that clearly shows your face.
        </IonText>
        <IonText className="text-start text-gray-600">
          On BetterDates you can only have one avatar, so pick the best one from
          your gallery.
        </IonText>
      </div>
    </div>
  );
}

function EmptyStateMissingLocation() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex w-full flex-col gap-4">
        <IonText className="text-center">
          Please set your location so we can match you with the people in your
          city.
          <br />
          <b>
            We only access your location once. You can update your location any
            time from your profile.
          </b>
        </IonText>
        <IonButton color="dark" routerLink="/change-location">
          Set Location
        </IonButton>
      </div>
    </div>
  );
}

function EmptyStateNoMatches() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex h-full w-full flex-col items-center justify-center">
        <div className="flex w-full flex-col items-center gap-4">
          <HeartIcon className="h-8 w-8 text-[#f05500]" />
        </div>
        <IonText className="text-center">
          Great things take time! We are carefully finding matches that spark
          your interest. Hold tight, the magic is about to happen...
        </IonText>
      </div>
    </div>
  );
}

function LoadedState() {
  const userContext = useContext(UserContext);
  const history = useHistory();
  const [profilePictureState, setProfilePictureState] =
    useState<ProfilePictureStateType>("innocent");
  const [matchIndex, setMatchIndex] = useState<RangeValue>(0);
  if (!userContext?.userState.loggedIn) {
    return <></>;
  }
  const queryClient = useQueryClient();
  const matches = useMemo(() => {
    return queryClient.getQueryData<MatchesResponse>([
      userContext.queryKeys.matches,
    ])?.matches;
  }, [queryClient.getQueryState([userContext.queryKeys.matches])?.data]);
  const focusedMatch = useMemo(() => {
    return matches ? matches[matchIndex as number] : undefined;
  }, [matches, matchIndex]);

  if (!focusedMatch || !matches) {
    return (
      <EmptyStateError
        errorMessage={ERROR_DEFAULT}
        onRetry={() => {
          queryClient.invalidateQueries({
            queryKey: userContext.queryKeys?.matches,
          });
        }}
      />
    );
  }
  useEffect(() => {
    setMatchIndex(generateInitialMatchIndex(matches.length));
  }, [matches]);
  return (
    <div className={`flex h-full w-full flex-col justify-start`}>
      <div className="h-full w-full"></div>
      <div className="h-full w-full">
        <MatchInfo
          state={{
            profilePictureState: {
              state: profilePictureState,
              transform: (to) => {
                setProfilePictureState(to);
              },
            },
          }}
          match={focusedMatch}
          onClick={() => {
            const match = focusedMatch;
            history.push(`/letters/${match.id}/${match.partyTwo.id}`);
          }}
        />
      </div>
      {matches.length > 1 && focusedMatch && (
        <>
          <div className="h-full w-full"></div>
          <div className="h-full w-full">
            <MatchSlider
              index={matchIndex}
              setMatchIndex={(index) => {
                setMatchIndex(index);
                setProfilePictureState("innocent");
              }}
              max={matches.length - 1}
              theme={focusedMatch.partyTwo.theme}
            />
          </div>
        </>
      )}
      <div className="h-full w-full"></div>
    </div>
  );
}

function generateInitialMatchIndex(numberOfMatches: number) {
  if (numberOfMatches % 2 === 0 || numberOfMatches === 1) {
    return 0;
  } else {
    return Math.floor(numberOfMatches / 2);
  }
}

type HomeState =
  | HomeStateLoading
  | HomeStateLoaded
  | HomeStateMissingPreferences
  | HomeStateMissingLocation
  | HomeStateMissingAvatar
  | HomeStateNoMatches
  | HomeStateError
  | HomeStateApplicationStatus;

type RejectionReason = {
  reason: string;
  description_EN: string;
};

type ApplicationStatusResponse = {
  items: {
    status: string;
    expand?: {
      rejectionReasons: RejectionReason[];
    };
  }[];
};
type HomeStateApplicationStatus = {
  type: "applicationStatus";
  status: ApplicationStatus;
  rejectionReasons?: RejectionReason[];
};

type HomeStateLoaded = {
  type: "loaded";
};

type HomeStateLoading = {
  type: "loading";
};

type HomeStateMissingPreferences = {
  type: "missingPreferences";
};

type HomeStateMissingLocation = {
  type: "missingLocation";
};

type HomeStateMissingAvatar = {
  type: "missingAvatar";
};

type HomeStateNoMatches = {
  type: "noMatches";
};

type HomeStateError = {
  type: "error";
  error: string;
  queryKey: string;
};
