import { IonButton, IonText } from "@ionic/react";
import React from "react";
import { ModalStateType } from "./Modal";

export default function AboutSelfExampleModal({
  setModalState,
}: PreferenceExampleModalProps) {
  return (
    <main className="flex h-full w-full flex-col px-4">
      <div className="flex h-full w-full flex-col gap-4 pt-4">
        <IonText className="text-lg font-bold">Your bio is too short!</IonText>
        <IonText>
          Please try to provide as much information as possible about yourself.
          This helps us to find your perfect match.
        </IonText>
        <IonText>For example, you can include things like:</IonText>
        <ul className="list-disc ps-4">
          <li>
            <IonText>
              Your hobbies, like the video game that you like to play, the show
              that you&apos;re currently watching, the book that you&apos;re
              reading, or if you do any sports and outdoor activities.
            </IonText>
          </li>
          <li>
            <IonText>
              Your work or school, like what do you do for work or anything
              about your school if you&apos;re studying.
            </IonText>
          </li>
          <li>
            <IonText>
              Anything about your family or if you have any pets.
            </IonText>
          </li>
        </ul>
        <div className="flex w-full flex-row justify-end pb-4">
          <IonButton
            className={"mt-8 h-9 w-20"}
            shape={"round"}
            color={"dark"}
            size={"small"}
            onClick={() => {
              setModalState({
                isVisible: false,
              });
            }}
          >
            OK
          </IonButton>
        </div>
      </div>
    </main>
  );
}

interface PreferenceExampleModalProps {
  setModalState: React.Dispatch<React.SetStateAction<ModalStateType>>;
}
